.progress {
    max-width: 350px;
    height: 30px !important;
    width: 80%;
    background-color: #767676;
    color: #fff;
    box-shadow: inset 0 1px 1px rgba(100, 100, 100, 0.1);
    box-sizing: initial;
    color: #fff;
    font-size: 11px;
    font-size: 0.6875rem;
    height: 12px;
    line-height: 1.182;
    margin: 6px 0;
    position: relative;
    text-align: center;
    width: 100%;
  }
  
  .progress-small {
    font-size: 9px;
    font-size: 0.5625rem;
    height: 6px;
    line-height: 0.875;
  }
  
  .progress-large {
    font-size: 16px;
    font-size: 1rem;
    height: 25px;
    line-height: 1.7;
  }
  
  .progress > span {
    /* background-color: #2bc253; */
    display: block;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: auto;
  }
  .progress.progress-stacked > span {
    display: inline-block;
    float: left;
  }
  
  .progress-striped > span:after, .progress-striped > span > span {
    background-size: 50px 50px;
    bottom: 0;
    content: "";
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
  .progress-striped.progress-animate > span:after, .progress-striped.progress-animate > span > span {
    -webkit-animation: move 2s linear infinite;
            animation: move 2s linear infinite;
  }
  
  @-webkit-keyframes move {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 50px 50px;
    }
  }