    /* @font-face {
        font-family: 'BrandonGrotesque';
        font-style: normal;
        src: url('./../assets/fonts/brandon-grotesque-black.woff');
    }

    @font-face {
        font-family: 'BrandonGrotesque-Light';
        font-style: normal;
        src: url('./../assets/fonts/brandon-grotesque-light.woff');
    }
     */

    @font-face {
        font-family: 'Helvetica Neue';
        font-style: normal;
        src: url('./../assets/fonts/helvetica-neue-medium.ttf');
    }

     @font-face {
        font-family: 'BrandonGrotesque-Black';
        font-style: normal;
        src: url('./../assets/fonts/brandon-grotesque-black.woff');
        font-weight: 400;
    }

    @font-face {
        font-family: 'BrandonGrotesque';
        src:
          url("https://s3-ap-southeast-1.amazonaws.com/uploads.zennya/fonts/BrandonG-Regular.otf")
          format("opentype");
    }
      
    /* @font-face {
        font-family: BrandonGrotesque;
        font-weight: 500;
        src:
          url("https://s3-ap-southeast-1.amazonaws.com/uploads.zennya/fonts/BrandonG-Medium.otf")
          format("opentype");
    }
       */
    @font-face {
        font-family: 'BrandonGrotesque-Bold';
        font-weight: 700;
        src:
          url("https://s3-ap-southeast-1.amazonaws.com/uploads.zennya/fonts/BrandonG-Bold.otf")
          format("opentype");
    }
      
    @media not print {
        body {
        overflow:auto;
        }
    }
    @media print {
    #progress {
        height: 0px;
    }
    .page-break { page-break-inside: avoid; page-break-before: always; }
    }
    #rootcontent {
    height:inherit;
    min-height:100%;
    }
    .view-container {
    min-height:100%;
    height:inherit;
    position:relative !important;
    }
    #main-container {
    width:100%;
    min-height:100%;
    font-family: 'Helvetica Neue', 'BrandonGrotesque',Lato, Arial,sans-serif;
    }
    #error-container {
    width:100%;
    min-height:100%;
    background:#86CAC7;
    font-family:'BrandonGrotesque',Lato,'Helvetica Neue',Arial,sans-serif;
    color:#FFF;
    font-size:24px;
    }
    .main-content {
    overflow-x:hidden;
    background:#FFF;
    }
    .main-message {
        font-family:'BrandonGrotesque',Lato,'Helvetica Neue',Arial,sans-serif !important;
        text-transform: uppercase;
        font-weight: 700 !important;
        font-size:calc(15px + (33 - 23) * ((100vw - 320px) / (833 - 320))) !important;
    }

    .content-container {
    width:100%;
    max-width:855px; /* 1024 - side */
    position:relative;
    }
    .content-full {
    background:#EEF6FE;
    width:100%;
    position:relative;
    }
    .content-highlight {
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    }

    .side-bar {
    position:relative;
    max-width:169px;
    min-width:64px;
    width:20%;
    background:#9BBBDC;
    }

    .side-bar-content {
    position:relative;
    min-width:52px;
    width:52%;
    margin:0 auto;
    display:block;
    }

    .icon-header-content {
    padding-top:4.56%;
    padding-bottom:4.56%;
    width:54.3%;
    max-width:170px;
    display:inline-block;
    }
    .icon-header-content img {
        width:80%;
        display:inline-block;
        max-width: 200px;
    }
    .avatar-content {
    /* width:49.09%; */
    width: 80%;
    max-width: 100px;
    border:solid 5px #dfe8e1;
    border-radius:50%;
    overflow:hidden;
    }
    .avatar-content2 {
    width:100%;
    padding-top:100%;
    position:relative;
    overflow:hidden;
    }
    .avatar-content img {
    width:100%;
    height:100%;
    object-fit: cover;
    display:inline-block;
    position:absolute;
    border-radius:50%;
    top:0;
    bottom:0;
    left:0;
    right:0;
    }
    .avatar-name-content {
        width: 100%;
        padding: 4.3%;
    }
    .name-content {
    /* padding:4.3%; */
    text-align:left !important;
    font-size:calc(14px + (33 - 23) * ((100vw - 320px) / (833 - 320))) !important;
    font-weight:600 !important;
    color:#2f2f2f !important;
    text-transform: uppercase !important;
    }
    .content-header {
    text-align:left !important;
    font-size:calc(19px + (33 - 23) * ((100vw - 320px) / (833 - 320))) !important;
    font-family: 'BrandonGrotesque-Black'
    }
    .position-content {
        color: #33333396 !important;
        text-align: left !important;
        font-size:calc(14px + (33 - 23) * ((100vw - 320px) / (833 - 320))) !important;
    }

    .text-left {
        text-align: left !important;
    }

    .misc-desc.date-text {
        font-family: "Helvetica Neue", "Roboto", "Arial", sans-serif !important;
    }

    .content-header-text {
        color: #2f2f2f !important;
        font-family: 'BrandonGrotesque-Black' !important;
    }

    .job-container {
    width:85.94%;
    max-width:575px;
    border:solid 5px #9BBBDC;
    border-radius:8px;
    text-align:center;
    padding:12px; /* TODO: Improve */
    }
    .job-title {
    display:block;
    font-size:calc(20px + (30 - 20) * ((100vw - 320px) / (833 - 320)));
    font-weight:bold;
    color:#9BBBDC !important;
    }
    .job-desc {
    display:block;
    padding-top:8px; /* TODO: Improve */
    font-size:calc(16px + (22 - 16) * ((100vw - 320px) / (833 - 320)));
    font-weight:500;
    color:#4A4A4A !important;
    }
    .job-desc-big {
    display:block;
    padding-top:8px; /* TODO: Improve */
    font-size:calc(30px + (40 - 30) * ((100vw - 320px) / (833 - 320)));
    font-weight:500;
    color:#4A4A4A !important;
    }
    .partner-container {
    padding-top:4.3%;
    padding-bottom:4.3%;
    width:89%;
    max-width:675px;
    font-size:calc(14px + (19 - 14) * ((100vw - 320px) / (833 - 320)));
    color:#4A4A4A !important;
    }
    .partner-container td {
    width:50%;
    padding-right:8px;
    }
    .barcode-content {
        padding-top:4.56%;
        /* padding-bottom:4.56%; */
        width:45%;
        max-width:200px;
    }

    .misc-container {
    width:89%;
    max-width:1024px;
    }
    .misc-title {
    padding-top:4.3%;
    display:block;
    font-size:calc(15px + (20 - 15) * ((100vw - 320px) / (833 - 320)));
    font-weight:bold;
    color:#4A4A4A !important;
    }
    .misc-desc {
    padding-top:3% !important; 
    padding-left: 1% !important;
    padding-right: 3% !important;
    display:block !important;
    font-size:calc(17px + (20 - 15) * ((100vw - 320px) / (833 - 320))) !important;
    color:#787879 !important;
    font-family: 'Helvetica Neue' !important;
    }
    .misc-desc a {
    color:#9BBBDC !important;
    text-decoration:underline;
    }

    .employee-status-text {
        padding-left: 20% !important;
        padding-right: 20% !important;
    }

    .covid-status-text {
        font-size: calc(17px + (20 - 15) * ((100vw - 320px) / (833 - 320))) !important;
    }

    .content-temperature {
    /* padding-top:7%; */
    display:inline-block;
    font-size:calc(25px + (30 - 25) * ((100vw - 320px) / (833 - 320)));
    font-weight:500;
    }
    .icon-temperature {
    width:96%;
    max-width:356px;
    display:inline-block;
    }

    .stat-green {
    color:#85CBB3 !important;
    }
    .stat-orange {
    color:#EEBE6A !important;
    }
    .stat-red {
    color:#F3948D !important;
    }

    /* .status-none {
        color: #85CBC8 !important;
    }

    .status-probable {
        color: #EEBE6A !important;
    }

    .status-confirmed {
        color: #F59B94 !important;
    } */

    .content-padding {
        padding: 4.3%;
    }

    .temperature-bar {
        display: grid; 
        grid-template-columns: repeat(3, minmax(45px, 1fr));
        width: 100%;
        text-align: center;
    }

    .covid-status-bar {
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
    }

    .temperature-signage {
        /* height: 75px; */
    }

    .temp-text {
        color: #2f2f2f !important;
        font-family: 'BrandonGrotesque-Black' !important;
        font-size:calc(40px + (33 - 23) * ((100vw - 320px) / (833 - 320))) !important;
    }
    
    .normal-text {
        color: #2f2f2f !important;
        font-size:calc(14px + (33 - 23) * ((100vw - 320px) / (833 - 320))) !important;
    }

    .dot {
        height: 25px;
        width: 25px;
        background-color: #bbb;
        border-radius: 50%;
        display: inline-block;
      }


    .temp-signage-text {
        padding-top: 20px;
        color: #fff !important;
        font-family: 'BrandonGrotesque-Black' !important;
        font-size:calc(14px + (33 - 23) * ((100vw - 320px) / (833 - 320))) !important;
    }

    .bar {
        padding: 5px;
    }

    .bar-text {
        color: #000 !important;
        /* font-weight: 700 !important; */
        font-size:calc(9px + (33 - 23) * ((100vw - 320px) / (833 - 320))) !important;
        text-transform: uppercase !important;
        font-family: 'Helvetica Neue';
    }


    .status-text {
        font-weight: 700 !important;
        font-size:calc(14px + (33 - 23) * ((100vw - 320px) / (833 - 320))) !important;
        text-transform: uppercase !important;
        padding: 10px;
    }

    .status-text-main {
    color: #fff !important;
    text-align: center !important;
    }

    .bar-low {
        background-color: #85CBB3 !important;
    }

    .bar-low-edge {
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
    }

    .bar-med {
        background-color: #EEBE6A !important;
    }

    .bar-high {
        background-color: #F59B94 !important;
    }

    .bar-high-edge {
        border-top-right-radius: 10px !important;
        border-bottom-right-radius: 10px !important
    }

    .round-corners {
        border-radius: 10px !important;
    }

    .temperature-display {
        /* left: 20%; */
        position: relative !important;
        /* right: 15px; */
        /* position: absolute; */
    }

    .arrow-temperature-display {
        position: absolute !important;
        font-size:calc(25px + (33 - 23) * ((100vw - 320px) / (833 - 320))) !important;
        padding-bottom: 3.5rem !important;
    }
/* 
    @media (max-width: 320px) {
    .arrow-temperature-display {
        top: 55% !important;
        }  
    }

    @media (min-width: 321px) and (max-width: 375px) {
        .arrow-temperature-display {
            top: 61% !important;
        }
    }

    @media (min-width: 376px) and (max-width: 425px) {
        .arrow-temperature-display {
            top: 68% !important;
        }
    }

    @media (min-width: 426px) and (max-width: 768px) {
        .arrow-temperature-display {
            top: 70.5% !important;
        }
    }

    @media (min-width: 769px) and (max-width: 1024px) {
        .arrow-temperature-display {
            top: 50.5% !important;
        }
    }

    @media (min-width: 1025px) and (max-width: 1440px) {
        .arrow-temperature-display {
            top: 50% !important;
        }
    }

    @media (min-width: 1441px) {
        .arrow-temperature-display {
            top: 37.5% !important;
        }
    }
 */


    /* @media (min-width: 426px) {
    .arrow-temperature-display {
        top: 70.5% !important;
    }
    }  */

    .temperature-green-text {
        left: -33% !important;
    }

    .temperature-orange-text {
        left: 0 !important;
    }

    .temperature-red-text {
        left: 33% !important;
    }

    .temperature-green-arrow {
        left: 18%   !important;
    }

    .temperature-orange-arrow {
        left: 47% !important;
    }

    .temperature-red-arrow {
        left: 80%   !important;
    }

    .text-left {
        text-align: left !important;
    }