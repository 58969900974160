.dropbtn {
    /* background-color: #4CAF50; */
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    right: 0px;
    top: 0px;
    bottom: 10px;
    /* background-color: #f9f9f9; */
    min-width: 160px;
    /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
    z-index: 2;
    width: 100%;
    padding-bottom: 40px;
    /* padding-bottom: 20px; */
  }

  /* .dropdown-content:last-child {
    display: none;
    position: relative !important;
  } */
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {
    /* background-color: #f1f1f1 */
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
    background-color: #f1f1f1
  }
  
  .dropdown:hover .dropbtn {
    /* background-color: #3e8e41; */
  }