/* Pop-up style */
.mapboxgl-popup-close-button {
    display: block;
    color: white;
    z-index: 1000;
  }
  
.mapboxgl-popup-content {
    font: 400 15px/22px 'Helvetica Neue', 'Roboto', Sans-serif;
    padding: 5;
    overflow-y: auto;
    background-color: black;
    color: white;
    opacity: 0.75;
    text-align: left;
}

.mapboxgl-popup-tip {
    display: none;
}

.marker-office {
    background-image: url('../assets/images/office.png');
    background-size: cover;
    width: 60px;
    height: 60px;
    /* border-radius: 50%; */
    cursor: pointer;
}

.item {
    position:relative;
    display:inline-block;
    width: 60px;
    height: 60px;
}
.click-item {
    cursor:pointer;
}

.notify-badge{
    position: absolute;
    right:-16px;
    top:-16px;
    height: 36px;
    width: 36px;
    display: inline-block;
    text-align: center;
    color:black;
    font-size: 700;
    padding:5px 5px;
    font-size: 10px;
}

.notify-badge-inner {
    /* background-color: white; 
    border-radius: inherit;  */
    /* height: 25px; 
    width: 25px; */
    /* padding: 5px 2px 10px 7px; */
}

.legend {
    background-color: #fff;
    border-radius: 3px;
    bottom: 20px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    padding: 5px;
    position: absolute;
    right: 10px;
    z-index: 1;
    opacity: 0.7;
    text-align: left;
}

.legendTitle {
    margin: 0 0 0;
}


.legendEntry {
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    margin-right: 5px;
    width: 10px;
    text-align: left;
    opacity: 0.7;
}


/* .notify-badge::before {
    border-radius: 50% !important;
}

.notify-badge::after {
    border-radius: 50% !important;
} */
  